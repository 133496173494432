import React from "react"
import { motion } from "framer-motion"
import styles from "./about.module.scss"

const testimonials = [
  {
    name: "JOHN",
    title: "Logistics Manager",
    company: "Global Manufacturing Co.",
    message:
      "Capitol Trucking has consistently exceeded our expectations with their reliable service. Whether it's handling large freight shipments or managing time-sensitive deliveries, they are always on top of their game. Their commitment to safety and efficiency is unparalleled, and we trust them with our most critical cargo.",
  },
  {
    name: "SUSAN",
    title: "Supply Chain Coordinator",
    company: "Midwest Distributors Inc.",
    message:
      "We've been working with Capitol Trucking for years, and their service has been nothing short of exceptional. They handle everything from LTL to full truckload shipments with the same level of care and attention. Their team is professional, responsive, and always ready to go the extra mile to ensure our needs are met.",
  },
  {
    name: "DAVID",
    title: "Operations Director",
    company: "National Retail Group",
    message:
      "Capitol Trucking has become our go-to partner for all things transportation. Their modern fleet and dedicated staff have made a huge difference in keeping our supply chain moving smoothly. They’re proactive in addressing any challenges and always deliver on time, no matter the distance or complexity of the shipment.",
  },
  {
    name: "JANE",
    title: "Procurement Manager",
    company: "East Coast Logistics",
    message:
      "Working with Capitol Trucking has streamlined our logistics operations. Their refrigerated services have been a game-changer for our temperature-sensitive freight. Their team is knowledgeable, courteous, and always available when we need them. They provide a level of service that we can count on day in and day out.",
  },
]

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
}

const staggerContainer = {
  hidden: { opacity: 1 },
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const About: React.FC = () => {
  return (
    <>
      {/* About Section */}
      <motion.div className={styles.parallaxContainerFirst}></motion.div>
      <motion.section
        className={styles.aboutSection}
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <div className={styles.container}>
          <h2>
            <span className={styles.companyName}>Capitol Trucking Inc.</span>
          </h2>
          <p>
            We offer superior Truck Load and LTL service to all of North
            America. Being an asset-based carrier, we are able to supply
            capacity during unexpected surges in volume or where there is a
            challenge to find available equipment. First of all, we are a safety
            and service-focused company with the goal to serve the needs of our
            customers while maintaining a profitable business model and
            providing an opportunity for all of our partners to grow.
          </p>
          <p>
            Over the years, we have expanded operations, growing our business to
            meet client demands and reduce operational costs across all modes
            and methods of shipping, offering cost-saving programs for
            consistent lanes. As experts in TL, LTL, refrigerated services, as
            well as protection from freeze, with one single point of contact,
            you can move just about any kind of freight anywhere in North
            America.
          </p>
          <p>
            We are continually searching for new ways to improve service to our
            customers, including major manufacturers and distributors, as well
            as air freight and LTL trucking companies that need coast-to-coast
            service. Our modern fleet of tractors is backed up by a team of
            committed professionals constantly looking for ways to meet the
            needs of our partners and drivers.
          </p>
        </div>
      </motion.section>

      {/* Testimonials Section */}
      <motion.section
        className={styles.testimonialsSection}
        initial="hidden"
        animate="visible"
        variants={staggerContainer}
      >
        <div className={styles.container}>
          <h2>Testimonials</h2>
          <motion.div className={styles.testimonials}>
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                className={styles.testimonialCard}
                variants={cardVariants}
              >
                <p className={styles.message}>"{testimonial.message}"</p>
                <div className={styles.author}>
                  <strong>{testimonial.name}</strong> {testimonial.title}
                  <br />
                  <span className={styles.company}>{testimonial.company}</span>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* Parallax Section */}
      <motion.div
        className={styles.parallaxContainer}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
      >
        <div className={styles.parallaxImage}></div>
        <div className={styles.content}>
          <h1>Why Capitol Trucking?</h1>
          <p>
            Reliable and trusted for all your logistics needs, ensuring safe and
            on-time deliveries.
          </p>

          <h2>Trusted Expertise</h2>
          <p>
            Years of industry experience providing efficient trucking services
            across the nation.
          </p>

          <h2>High Security</h2>
          <p>
            State-of-the-art security measures to keep your shipments safe at
            every step.
          </p>

          <h2>Customer-Centered</h2>
          <p>
            Offering flexible solutions tailored to meet each customer’s unique
            transportation requirements.
          </p>
        </div>
      </motion.div>
    </>
  )
}

export default About
