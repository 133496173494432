import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styles from "./gallery.module.scss"
import CallToAction from "../../lib/CallToAction"

import img1 from "../../assets/img/gallery/cti1.jpg"
import img2 from "../../assets/img/gallery/cti2.jpg"
import img3 from "../../assets/img/gallery/cti3.jpg"
import img4 from "../../assets/img/gallery/cti4.jpg"
import img5 from "../../assets/img/gallery/cti5.jpg"
import img6 from "../../assets/img/gallery/cti6.jpg"
import img7 from "../../assets/img/gallery/cti7.jpg"
import img8 from "../../assets/img/gallery/cti8.jpg"
import img9 from "../../assets/img/gallery/cti9.jpg"
import img10 from "../../assets/img/gallery/cti10.jpg"
import img11 from "../../assets/img/gallery/cti11.jpg"
import img12 from "../../assets/img/gallery/cti12.jpg"
import img13 from "../../assets/img/gallery/cti14.jpg"
import img14 from "../../assets/img/gallery/cti15.jpg"
import img16 from "../../assets/img/gallery/cti17.jpg"
import img17 from "../../assets/img/gallery/cti18.jpg"

const Gallery: React.FC = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img16,
    img17,
  ]

  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const openModal = (image: string) => {
    setSelectedImage(image)
  }

  const closeModal = () => {
    setSelectedImage(null)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const imageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  }

  return (
    <div>
      <motion.div
        className={styles.galleryContainer}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.8 }}
      >
        <h2 className={styles.galleryHeading}>Gallery</h2>
        <p className={styles.galleryDescription}>
          Here is our gallery showcasing our fleet of modern, reliable trucks in
          action. Our vehicles travel thousands of kilometers daily, delivering
          goods across the country. Each photo reflects our commitment to
          professionalism, quality, and timely, safe deliveries, no matter the
          road or weather conditions. Explore our fleet in motion!
        </p>

        <motion.div
          className={styles.imageGrid}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {images.map((src, index) => (
            <motion.div
              key={index}
              className={styles.imageWrapper}
              onClick={() => openModal(src)}
              variants={imageVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.3 }}
            >
              <img src={src} alt={`Gallery Image ${index + 1}`} />
              <motion.div
                className={styles.imageOverlay}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <p>Truck Image {index + 1}</p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className={styles.modal}
            onClick={closeModal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <img src={selectedImage} alt="Enlarged" />
              <motion.button
                className={styles.closeButton}
                onClick={closeModal}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                &times;
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <CallToAction />
    </div>
  )
}

export default Gallery
