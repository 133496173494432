import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik"
import { motion } from "framer-motion"
import { quoteFormValidationSchema } from "../../pkg/validators"
import API from "../../assets/api"
import styles from "./quote.module.scss"

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
}

const staggerContainer = {
  hidden: { opacity: 1 },
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const formFieldVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
}

interface QuoteFormValues {
  companyName: string
  brokerName: string
  phone: string
  email: string
  pickupDate: string
  deliveryDate: string
  zipCode: string
  fullLTL: string
  weight: string
  type: string
  additionalInfo: string
}

const GetAQuote: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const onSubmit = (
    values: QuoteFormValues,
    { setSubmitting, resetForm }: FormikHelpers<QuoteFormValues>
  ) => {
    const formData = { ...values, category: "quote request" }
    API.sendGetAQuoteRequest(formData).then(() => {
      setIsSubmitted(true)
      resetForm()
      setSubmitting(false)
    })
  }

  return (
    <>
      <div className={styles.parallaxContainer}></div>
      <motion.div
        className={styles.quoteContainer}
        initial="hidden"
        animate="visible"
        variants={staggerContainer}
      >
        <motion.div className={styles.headerText} variants={sectionVariants}>
          <h1>GET A QUOTE</h1>
          <p>
            Unlock your potential with the best solutions tailored just for you.
            Start your journey today and discover the difference!
          </p>
        </motion.div>

        <motion.div
          className={styles.formContainer}
          initial="hidden"
          animate="visible"
          variants={staggerContainer}
        >
          <Formik
            initialValues={{
              companyName: "",
              brokerName: "",
              phone: "",
              email: "",
              pickupDate: "",
              deliveryDate: "",
              zipCode: "",
              fullLTL: "",
              weight: "",
              type: "van",
              additionalInfo: "",
            }}
            validationSchema={quoteFormValidationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <motion.div
                  className={styles.inputRow}
                  variants={formFieldVariants}
                >
                  <div className={styles.inputGroup}>
                    <label htmlFor="companyName">Company Name</label>
                    <Field
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name"
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="brokerName">Contact Name</label>
                    <Field
                      type="text"
                      id="brokerName"
                      name="brokerName"
                      placeholder="Broker Name"
                    />
                    <ErrorMessage
                      name="brokerName"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className={styles.inputRow}
                  variants={formFieldVariants}
                >
                  <div className={styles.inputGroup}>
                    <label htmlFor="phone">Phone Number</label>
                    <Field
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="email">Email Address</label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className={styles.inputRow}
                  variants={formFieldVariants}
                >
                  <div className={styles.inputGroup}>
                    <label htmlFor="pickupDate">Pickup Date</label>
                    <Field type="date" id="pickupDate" name="pickupDate" />
                    <ErrorMessage
                      name="pickupDate"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="deliveryDate">Delivery Date</label>
                    <Field type="date" id="deliveryDate" name="deliveryDate" />
                    <ErrorMessage
                      name="deliveryDate"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className={styles.inputRow}
                  variants={formFieldVariants}
                >
                  <div className={styles.inputGroup}>
                    <label htmlFor="zipCode">Zip Code</label>
                    <Field
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      placeholder="Zip Code"
                    />
                    <ErrorMessage
                      name="zipCode"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="fullLTL">Full/LTL</label>
                    <Field
                      type="text"
                      id="fullLTL"
                      name="fullLTL"
                      placeholder="Full/LTL"
                    />
                    <ErrorMessage
                      name="fullLTL"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className={styles.inputRow}
                  variants={formFieldVariants}
                >
                  <div className={styles.inputGroup}>
                    <label htmlFor="weight">Weight</label>
                    <Field
                      type="text"
                      id="weight"
                      name="weight"
                      placeholder="Weight"
                    />
                    <ErrorMessage
                      name="weight"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="type">Type</label>
                    <Field as="select" id="type" name="type">
                      <option value="van">Van</option>
                      <option value="flatbed">Flatbed</option>
                      <option value="reefer">Reefer</option>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                </motion.div>

                <motion.div
                  className={`${styles.inputGroup} ${styles.fullWidth}`}
                  variants={formFieldVariants}
                >
                  <Field
                    as="textarea"
                    id="additionalInfo"
                    name="additionalInfo"
                    placeholder="Additional Information"
                    className={styles.textarea}
                  />
                  <ErrorMessage
                    name="additionalInfo"
                    component="div"
                    className={styles.error}
                  />
                </motion.div>

                <motion.button
                  type="submit"
                  disabled={isSubmitting}
                  className={styles.submitButton}
                  variants={buttonVariants}
                  whileHover="hover"
                >
                  Submit
                </motion.button>
              </Form>
            )}
          </Formik>

          {isSubmitted && (
            <motion.div
              className={styles.successMessage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <p>
                Thank you! Your quote request has been submitted successfully.
              </p>
            </motion.div>
          )}
        </motion.div>
      </motion.div>

      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>
    </>
  )
}

export default GetAQuote
