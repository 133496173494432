import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./App.scss"
import Layout from "./lib/Layout"

// views
import Home from "./views/Home"
import NotFound from "./views/NotFound"
import About from "./views/About"
import OurServices from "./views/OurServices"
import Gallery from "./views/Gallery"
import Contact from "./views/Contact"
import Drivers from "./views/Drivers"
import Owners from "./views/Owners"
import GetAQuote from "./views/GetAQuote"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "ourservices",
        element: <OurServices />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "/contact/drivers",
        element: <Drivers />,
      },
      {
        path: "/contact/owners",
        element: <Owners />,
      },
      {
        path: "/contact/getaquote",
        element: <GetAQuote />,
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
