import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import styles from "./home.module.scss"
import {
  FaClock,
  FaTruck,
  FaCalendarAlt,
  FaClipboardCheck,
  FaLanguage,
  FaPercent,
  FaRoad,
  FaMapMarkedAlt,
  FaWarehouse,
  FaTools,
} from "react-icons/fa"
import Button from "../../lib/ButtonRed"
import { useNavigate } from "react-router-dom"

//images
import first from "./../../assets/img/first.png"
import imgPrivate from "./../../assets/img/istock10.jpg"
import imgBusiness from "./../../assets/img/istock6.jpg"
import img3 from "./../../assets/img/istock6.jpg"
// import img2 from "./../../assets/img/istock12.jpg"
import img2 from "./../../assets/img/pexels3.jpg"
import img1 from "./../../assets/img/pexels4.jpg"

const imageContainerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
}

const serviceItemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
}

const customerOptionVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
}

const mapVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
}

const headerVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
}

const servicesContainerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const services = [
  {
    icon: <FaClock />,
    title: "2/3 h",
    subtitle: "for sending a vehicle for loading",
  },
  {
    icon: <FaTruck />,
    title: "1000",
    subtitle: "loads monthly",
  },
  {
    icon: <FaCalendarAlt />,
    title: "17 years",
    subtitle: "company existence",
  },
  {
    icon: <FaClipboardCheck />,
    title: "15 min",
    subtitle: "CMR scan after unloading",
  },
  {
    icon: <FaLanguage />,
    title: "6",
    subtitle: "languages supported",
  },
  {
    icon: <FaPercent />,
    title: "99.8%",
    subtitle: "delivery success rate",
  },
  {
    icon: <FaRoad />,
    title: "1200 km",
    subtitle: "average daily distance traveled",
  },
  {
    icon: <FaMapMarkedAlt />,
    title: "24/7 tracking",
    subtitle: "real-time vehicle tracking",
  },
  {
    icon: <FaWarehouse />,
    title: "50,000 sq. m",
    subtitle: "warehouse space available",
  },
  {
    icon: <FaTools />,
    title: "On-site support",
    subtitle: "24/7 technical assistance",
  },
]

const images = [img1, img2, img3]

const Home: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const navigate = useNavigate()

  const goToOwners = () => {
    navigate("/contact/owners")
  }

  const goToDrivers = () => {
    navigate("/contact/drivers")
  }

  return (
    <>
      {/* Header Section */}
      <motion.div
        className={styles.header}
        style={{
          backgroundImage: `linear-gradient(rgba(4, 9, 30, 0.3), rgba(4, 9, 30, 0.2)), url(${images[currentImage]})`,
        }}
        initial="hidden"
        animate="visible"
        variants={imageContainerVariants}
      >
        <div className={styles.redOverlay}></div>
        <div className={styles.textBox}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={headerVariants}
            className={styles.headerContent}
          >
            <h2>Capitol Trucking</h2>
            <p>
              At Capitol Trucking, we are committed to providing reliable and
              efficient transportation services for businesses of all sizes.
              Whether you're moving goods across town or across the country, our
              experienced team is here to support your logistics needs.
            </p>
            <Button text="Contact Us" linkTo="/contact" />
          </motion.div>
        </div>
      </motion.div>
      <div className={styles.scrollIndicator}>
        <span className={styles.arrow}></span>
        <span className={styles.arrow}></span>
        <span className={styles.arrow}></span>
      </div>

      {/* Services Icon Section */}
      <section className={styles.servicesSection}>
        <h2 className={styles.heading}>
          Fast and Reliable Delivery Services That Meet Your Timeline
        </h2>
        <p className={styles.description}>
          We offer services that align with your business needs. Our efficiency
          and expertise ensure timely deliveries and smooth operations in
          multiple languages.
        </p>
        <motion.div
          className={styles.services}
          initial="hidden"
          animate="visible"
          variants={servicesContainerVariants}
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              className={styles.serviceItem}
              variants={serviceItemVariants}
            >
              <div className={styles.icon}>{service.icon}</div>
              <h3 className={styles.serviceTitle}>{service.title}</h3>
              <p className={styles.serviceSubtitle}>{service.subtitle}</p>
            </motion.div>
          ))}
        </motion.div>
      </section>

      {/* About Info Section */}

      <section className={styles.infoSection}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h4 className={styles.subtitle}>A Few Words About Us</h4>
            <h2 className={styles.title}>
              We treat every client and transport service with individual
              attention.
              <br />
              We provide tailor-made solutions.
            </h2>
            <div className={styles.underline}></div>
          </div>
          <div className={styles.right}>
            <p>
              We are a family-owned transport and logistics company operating.
              We specialize in express Just-In-Time transport throughout
              America.
            </p>
            <p>
              Thanks to our extensive experience, we can anticipate critical
              transport points and prevent potential problems from escalating.
              Our experienced team of controllers monitors your shipment 24/7.
            </p>
            <Button text="Learn More" linkTo="/about" />
          </div>
        </div>
      </section>

      <section className={styles.aboutSection}>
        <motion.div
          className={styles.textContainer}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h2>Safety is Our Top Priority at Capitol Trucking</h2>
          <p>
            At Capitol Trucking, safety drives every decision we make. Our fleet
            features only the newest models of trucks and trailers, each
            equipped with advanced AI-powered cameras and tracking devices. This
            cutting-edge technology not only helps us anticipate potential risks
            but also ensures full compliance with industry standards.
          </p>

          <p>
            Prioritizing safety and performance through our state-of-the-art
            fleet. Our dedication to safety goes beyond the road. ensuring your
            cargo is always transported using impeccably maintained equipment.
            From the moment your shipment leaves, to its final destination, you
            can trust Capitol Trucking for reliable, secure transportation
            solutions.
          </p>
          <p>
            Choose Capitol Trucking for unmatched dedication to safety,
            reliability, and top-notch service.
          </p>
        </motion.div>

        <motion.div
          className={styles.imageContainer}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={first} />
          <p className={styles.ownerName}>Capitol Trucking INC</p>
        </motion.div>
      </section>

      {/* Two Photos Section */}
      <section className={styles.container}>
        <motion.div
          className={styles.customerOption}
          style={{ backgroundImage: `url(${imgPrivate})` }}
          initial="hidden"
          whileInView="visible"
          variants={customerOptionVariants}
          onClick={goToOwners}
        >
          <div className={styles.overlay}></div>
          <div className={styles.content}>
            <h2>For Owner-Operators</h2>
            <p>
              As an owner-operator, you know the importance of keeping your
              truck moving and maximizing your profits. Capitol Trucking Inc
              offers you the perfect opportunity to work with a transportation
              company that supports owner-operators every step of the way.
            </p>
          </div>
        </motion.div>

        <motion.div
          className={styles.customerOption}
          style={{ backgroundImage: `url(${imgBusiness})` }}
          initial="hidden"
          whileInView="visible"
          variants={customerOptionVariants}
          onClick={goToDrivers}
        >
          <div className={styles.overlay}></div>
          <div className={styles.content}>
            <h2>For Drivers</h2>
            <p>
              Are you looking for a stable and well-paying job as a truck
              driver? Capitol Trucking Inc offers incredible opportunities for
              professional drivers who want to be part of a trusted and dynamic
              transportation company.
            </p>
          </div>
        </motion.div>
      </section>

      {/* Map Section */}
      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={mapVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>
    </>
  )
}

export default Home
