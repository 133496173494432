import React, { useState } from "react"
import { motion } from "framer-motion"
import { useForm } from "react-hook-form"
import {
  FaHandshake,
  FaMoneyBillAlt,
  FaCalendarAlt,
  FaTruck,
} from "react-icons/fa"
import styles from "./drivers.module.scss"
import CallToAction from "../../lib/CallToAction"
import API from "../../assets/api"

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
}

const staggerContainer = {
  hidden: { opacity: 1 },
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const formFieldVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
}

const Drivers: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("") // Dodanie stanu na wiadomość błędu

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  })

  const onSubmit = (data: {
    name: string
    email: string
    phone: string
    message: string
  }) => {
    const formData = { ...data, category: "driver application" }

    API.sendDriverRequest(formData)
      .then(() => {
        setIsSubmitted(true)
        setShowSuccessMessage(true)
        setErrorMessage("") // Wyczyszczenie wiadomości błędu po udanej wysyłce
        reset() // Resetowanie formularza po wysłaniu
      })
      .catch((error) => {
        console.error("Błąd przy wysyłaniu formularza:", error)
        setErrorMessage(
          error?.message || "Error while sending the form. Please try again."
        ) // Ustawienie wiadomości błędu
      })
  }

  return (
    <>
      <div className={styles.parallaxContainer}></div>

      {/* Main Section */}
      <motion.section
        className={styles.driversSection}
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h2 className={styles.sectionHeading}>For Drivers</h2>
        <div className={styles.underline}></div>
        <p className={styles.description}>
          Join Capitol Trucking Inc and Kickstart Your Driving Career!
        </p>
        <p className={styles.details}>
          Are you looking for a stable and well-paying job as a truck driver?
          Capitol Trucking Inc offers incredible opportunities for professional
          drivers who want to be part of a trusted and dynamic transportation
          company.
        </p>
      </motion.section>

      {/* Info Section with Icons */}
      <div className={styles.container}>
        <motion.div
          className={styles.infoSection}
          initial="hidden"
          animate="visible"
          variants={staggerContainer}
        >
          <h2>Start working with us</h2>
          <p>
            Fill out the form, and we'll get in touch with you to discuss
            partnership opportunities.
          </p>
          <h3>What we offer our partners:</h3>
          <ul>
            {[
              { icon: <FaHandshake size={24} />, text: "Steady collaboration" },
              { icon: <FaMoneyBillAlt size={24} />, text: "Competitive rates" },
              { icon: <FaCalendarAlt size={24} />, text: "Timely payments" },
              { icon: <FaTruck size={24} />, text: "Dispatcher availability" },
            ].map((item, index) => (
              <motion.li key={index} variants={formFieldVariants}>
                <div className={styles.iconCircle}>{item.icon}</div>
                {item.text}
              </motion.li>
            ))}
          </ul>
        </motion.div>

        {/* Form Section */}
        <motion.div
          className={styles.formContainer}
          initial="hidden"
          animate="visible"
          variants={staggerContainer}
        >
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <motion.div
              className={styles.inputGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="name">Name or Company Name *</label>
              <input
                type="text"
                id="name"
                placeholder="John Doe or ABC Logistics"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <div className={styles.error}>{errors.name.message}</div>
              )}
            </motion.div>

            <motion.div
              className={styles.inputGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                placeholder="you@example.com"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <div className={styles.error}>{errors.email.message}</div>
              )}
            </motion.div>

            <motion.div
              className={styles.inputGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="phone">Phone Number *</label>
              <input
                type="tel"
                id="phone"
                placeholder="+1 (000) 444-4444"
                {...register("phone", { required: "Phone number is required" })}
              />
              {errors.phone && (
                <div className={styles.error}>{errors.phone.message}</div>
              )}
            </motion.div>

            <motion.div
              className={styles.inputGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="message">Message or Question *</label>
              <textarea
                id="message"
                placeholder="Your message or question here..."
                {...register("message", { required: "Message is required" })}
              />
              {errors.message && (
                <div className={styles.error}>{errors.message.message}</div>
              )}
            </motion.div>

            <motion.button
              type="submit"
              className={styles.submitButton}
              variants={buttonVariants}
              whileHover="hover"
            >
              Submit
            </motion.button>

            {isSubmitted && showSuccessMessage && (
              <motion.div
                className={styles.successMessage}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <p>
                  Thank you for reaching out! We will get back to you shortly.
                </p>
              </motion.div>
            )}

            {/* Wyświetlenie błędu w razie problemu z wysyłką */}
            {errorMessage && (
              <motion.div
                className={styles.errorMessage}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <p>{errorMessage}</p>
              </motion.div>
            )}
          </form>
        </motion.div>
      </div>

      {/* Map Section */}
      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>

      <CallToAction />
    </>
  )
}

export default Drivers
