import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik"
import { motion } from "framer-motion"
import {
  FaHandshake,
  FaMoneyBillAlt,
  FaCalendarAlt,
  FaBolt,
  FaTruck,
} from "react-icons/fa"
import styles from "./owners.module.scss"
import CallToAction from "../../lib/CallToAction"
import API from "../../assets/api"
import { contactFormValidationSchema } from "../../pkg/validators"

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
}

const listItemVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
}

const formFieldVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
}

const mapVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
}

// Define the form values type
interface OwnerFormValues {
  name: string
  email: string
  phone: string
  message: string
  agree: boolean
}

const Owners: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  // Define types for the form helpers using FormikHelpers
  const onSubmit = (
    values: OwnerFormValues,
    { setSubmitting, resetForm }: FormikHelpers<OwnerFormValues>
  ) => {
    const formData = { ...values, category: "owner partnership request" }
    API.sendOwnerRequest(formData)
      .then(() => {
        setIsSubmitted(true)
        resetForm()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <div className={styles.parallaxContainer}></div>

      {/* Section Heading and Description */}
      <motion.section
        className={styles.ownersSection}
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h2 className={styles.sectionHeading}>For Owners</h2>
        <div className={styles.underline}></div>
        <p className={styles.description}>
          Partner with Capitol Trucking Inc and Maximize Your Earnings!
        </p>
        <p className={styles.details}>
          As a truck owner or fleet operator, Capitol Trucking Inc offers you
          the perfect opportunity to grow your business with consistent work,
          competitive rates, and full support. We ensure that your trucks are
          always on the road, delivering goods efficiently and profitably.
        </p>
      </motion.section>

      {/* Info Section with Icons */}
      <div className={styles.container}>
        <motion.div
          className={styles.infoSection}
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
        >
          <h2>Start working with us</h2>
          <p>
            Fill out the form, and we'll get in touch with you to discuss
            partnership opportunities.
          </p>
          <h3>What we offer our partners:</h3>
          <ul>
            {[
              {
                icon: <FaHandshake size={24} />,
                text: "Consistent Freight Opportunities",
              },
              {
                icon: <FaMoneyBillAlt size={24} />,
                text: "Maximized Profit Margins",
              },
              {
                icon: <FaBolt size={24} />,
                text: "Fuel Discounts & Maintenance Support",
              },
              { icon: <FaTruck size={24} />, text: "24/7 Logistics Support" },
            ].map((item, index) => (
              <motion.li key={index} variants={listItemVariants}>
                <div className={styles.iconCircle}>{item.icon}</div>
                {item.text}
              </motion.li>
            ))}
          </ul>
        </motion.div>

        {/* Form Section */}
        <motion.div
          className={styles.formContainer}
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
        >
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              message: "",
              agree: false,
            }}
            validationSchema={contactFormValidationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={styles.form}>
                <motion.div
                  className={styles.inputGroup}
                  variants={formFieldVariants}
                >
                  <label htmlFor="name">Name or Company Name *</label>
                  <Field
                    type="text"
                    name="name"
                    placeholder="John Doe or ABC Logistics"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className={styles.error}
                  />
                </motion.div>

                <motion.div
                  className={styles.inputGroup}
                  variants={formFieldVariants}
                >
                  <label htmlFor="email">Email *</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="you@example.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={styles.error}
                  />
                </motion.div>

                <motion.div
                  className={styles.inputGroup}
                  variants={formFieldVariants}
                >
                  <label htmlFor="phone">Phone Number *</label>
                  <Field
                    type="tel"
                    name="phone"
                    placeholder="+1 (000) 444-4444"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className={styles.error}
                  />
                </motion.div>

                <motion.div
                  className={styles.inputGroup}
                  variants={formFieldVariants}
                >
                  <label htmlFor="message">Message or Question *</label>
                  <Field
                    as="textarea"
                    name="message"
                    placeholder="Your message or question here..."
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className={styles.error}
                  />
                </motion.div>

                <motion.button
                  type="submit"
                  disabled={isSubmitting}
                  whileHover="hover"
                  variants={buttonVariants}
                  className={styles.submitButton}
                >
                  Submit
                </motion.button>

                {isSubmitted && (
                  <motion.div
                    className={styles.successMessage}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <p>
                      Thank you for reaching out! We will get back to you
                      shortly.
                    </p>
                  </motion.div>
                )}
              </Form>
            )}
          </Formik>
        </motion.div>
      </div>

      {/* Map Section */}
      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={mapVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>

      <CallToAction />
    </>
  )
}

export default Owners
