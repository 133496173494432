import * as Yup from "yup"

export const contactFormValidationSchema = Yup.object({
  name: Yup.string().required("Name or company name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must consist of digits only")
    .required("Phone number is required"),
  message: Yup.string().required("Message is required"),
})

export const quoteFormValidationSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  brokerName: Yup.string().required("Broker name is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[+]?[\d\s-]{10,15}$/, "Phone number is not valid"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  pickupDate: Yup.date().required("Pickup date is required"),
  deliveryDate: Yup.date().required("Delivery date is required"),
  zipCode: Yup.string().required("Zip code is required"),
  fullLTL: Yup.string().required("Full/LTL is required"),
  weight: Yup.number()
    .typeError("Weight must be a number")
    .positive("Weight must be positive")
    .required("Weight is required"),
  type: Yup.string().required("Type is required"),
  additionalInfo: Yup.string(),
})
