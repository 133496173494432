import React from "react"
import styles from "./call.module.scss"

const CallToAction: React.FC = () => {
  return (
    <section className={styles.callToAction}>
      <h2>Need a Reliable Transport Solution?</h2>
      <p>
        Get in touch with us today and get a free quote for your logistics
        needs!
      </p>
      <a href="/contact/getaquote">
        <button className={styles.ctaButton}>Get a Quote</button>
      </a>
    </section>
  )
}

export default CallToAction
