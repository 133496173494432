import React, { useState } from "react"
import { motion } from "framer-motion"
import {
  FaTruck,
  FaSnowflake,
  FaClock,
  FaWarehouse,
  FaBox,
  FaRoute,
  FaGlobeAmericas,
  FaBoxOpen,
  FaArrowRight,
} from "react-icons/fa"
import styles from "./ourservices.module.scss"

type AccordionItem = {
  question: string
  answer: string
}

const accordionData: AccordionItem[] = [
  {
    question: "Always Available",
    answer:
      "We are committed to being available around the clock. Quick and effective communication ensures all your needs are met swiftly.",
  },
  {
    question: "Reduce Your Stress",
    answer:
      "Our team of experienced professionals takes the hassle out of transportation logistics, offering you peace of mind throughout the process.",
  },
  {
    question: "A Personal Service",
    answer:
      "We take pride in tailoring our services to meet your specific needs, providing a personal touch that sets us apart from the rest.",
  },
  {
    question: "Flexible Transportation Solutions",
    answer:
      "From dry van to air freight, we offer a wide range of transportation solutions that can be customized to your requirements.",
  },
  {
    question: "Proven Industry Expertise",
    answer:
      "With years of experience, we have the expertise to ensure your goods are delivered safely and on time, every time.",
  },
]
const servicesContainerVariants = {
  hidden: { opacity: 1 },
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const serviceItemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
}

const buttonVariants = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
}

// Animation variants for the accordion
const accordionVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: {
    opacity: 1,
    height: "auto",
    transition: { duration: 0.5 },
  },
}
const mapVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
}

const Accordion: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <div className={styles.accordion}>
      {accordionData.map((item, index) => (
        <div key={index} className={styles.accordionItem}>
          <div
            className={styles.accordionHeader}
            onClick={() => toggleAccordion(index)}
          >
            <h3>{item.question}</h3>
            <span>{activeIndex === index ? "-" : "+"}</span>
          </div>
          <motion.div
            className={styles.accordionContent}
            initial="hidden"
            animate={activeIndex === index ? "visible" : "hidden"}
            variants={accordionVariants}
          >
            <p>{item.answer}</p>
          </motion.div>
        </div>
      ))}
    </div>
  )
}

const Services: React.FC = () => {
  return (
    <div>
      <div className={styles.parallaxContainer}></div>

      {/* Services Section */}
      <motion.div
        className={styles.servicesContainer}
        initial="hidden"
        animate="visible"
        variants={servicesContainerVariants}
      >
        <motion.h2
          className={styles.heading}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        >
          Our Services
        </motion.h2>
        <p>
          We understand the importance of clear and consistent communication
          with our customers and teams. Quick, decisive action is at the heart
          of our approach, enabling others to excel. Even under pressure, we
          remain respectful, focused, and dedicated to finding ways to enhance
          collaboration, ensuring that every effort moves us closer to success.
        </p>

        {/* Services List */}
        <motion.div
          className={styles.serviceList}
          initial="hidden"
          animate="visible"
          variants={servicesContainerVariants}
        >
          {[
            { icon: <FaTruck />, label: "Dry Van" },
            { icon: <FaSnowflake />, label: "Reefer" },
            { icon: <FaClock />, label: "Expedited" },
            { icon: <FaGlobeAmericas />, label: "Coast to Coast" },
            { icon: <FaWarehouse />, label: "Warehousing" },
            { icon: <FaBox />, label: "Storage" },
            { icon: <FaTruck />, label: "Power Only" },
            { icon: <FaRoute />, label: "Cross Country" },
            { icon: <FaBoxOpen />, label: "Distribution" },
            { icon: <FaArrowRight />, label: "Drayage" },
          ].map((service, index) => (
            <motion.div
              key={index}
              className={styles.serviceItem}
              variants={serviceItemVariants}
              whileHover={{ scale: 1.1 }}
            >
              {service.icon}
              <p>{service.label}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Accordion Section */}
        <motion.div
          className={styles.accordionContainer}
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
          }}
        >
          <h2 className={styles.heading}>Why Choose Us</h2>
          <Accordion />
        </motion.div>
      </motion.div>

      {/* Map Section */}
      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={mapVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>
    </div>
  )
}

export default Services
