import axios, { AxiosInstance } from "axios"

class API {
  baseURL: string
  instance: AxiosInstance

  constructor(baseURL: string) {
    this.baseURL = baseURL
    this.instance = axios.create({
      baseURL: baseURL,
    })
  }

  sendContactRequest = async (contactData: any) => {
    return this.instance.post(`/contact/ctifleet`, contactData)
  }

  sendMailWithAttachment = async (contactData: any) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    return this.instance.post(
      "/contact/ctifleet/attachments",
      contactData,
      config
    )
  }

  sendDriverRequest = async (contactData: any) => {
    return this.instance.post(`/contact/ctifleet-drivers`, contactData)
  }

  sendOwnerRequest = async (contactData: any) => {
    return this.instance.post(`/contact/ctifleet-owners`, contactData)
  }

  sendGetAQuoteRequest = async (contactData: any) => {
    return this.instance.post(`/contact/ctifleet-getaquote`, contactData)
  }
}

const api = new API("https://api.onitsoft.com")

export default api
