import React from "react"
import { Link } from "react-router-dom"
import styles from "./button.module.scss"

type ButtonProps = {
  text: string
  linkTo: string
}

const Button: React.FC<ButtonProps> = ({ text, linkTo }) => {
  return (
    <Link to={linkTo} className={styles.truckButton}>
      {text}
    </Link>
  )
}

export default Button
