import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { motion } from "framer-motion"
import styles from "./nav.module.scss"
import { FaLinkedin, FaFacebookSquare, FaYoutube } from "react-icons/fa"
import logo from "../../../../assets/img/cti.png"

const Nav = (): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const toggleDrawer = (): void => {
    setIsDrawerOpen((prev) => !prev)
  }

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isDrawerOpen])

  const { pathname } = useLocation()
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsDrawerOpen(false)
    }
  }, [pathname])

  useEffect(() => {
    // clear opened drawer if window resizes
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsDrawerOpen(false)
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <nav className={styles.wrapper}>
      <div className={styles.bar}>
        <Link to={"/"}>
          <img src={logo} alt="Company Logo" className={styles.logo} />
        </Link>
        <span className={styles.spacer} />
        <ul className={styles.links}>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/about"}>About</Link>
          </li>
          <li>
            <Link to={"/ourservices"}>Our Services</Link>
          </li>
          <li>
            <Link to={"/gallery"}>Gallery</Link>
          </li>
          <li>
            <Link to={"/contact/drivers"}>Drivers</Link>
          </li>
          <li>
            <Link to={"/contact/owners"}>Owners</Link>
          </li>
          <li>
            <Link to={"/contact"}>Contact</Link>
          </li>
          <li>
            <Link to={"/contact/getaquote"}>Get a Quote</Link>
          </li>
        </ul>
        <div
          className={`${styles.burgerMenu} ${
            isDrawerOpen && styles.burgerMenuActive
          }`}
          onClick={toggleDrawer}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <motion.div
        className={styles.drawer}
        initial={{ x: "100%", y: "-100%" }}
        animate={{
          x: isDrawerOpen ? 0 : "100%",
          y: isDrawerOpen ? 0 : "-100%",
        }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: isDrawerOpen ? 1 : 0, x: isDrawerOpen ? 0 : -20 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <Link
            to={"/"}
            className={`${styles.mobileLink} ${
              pathname === "/" && styles.mobileLinkActive
            }`}
          >
            Home
          </Link>

          <Link
            to={"/about"}
            className={`${styles.mobileLink} ${
              pathname === "/about" && styles.mobileLinkActive
            }`}
          >
            About
          </Link>

          <Link
            to={"/gallery"}
            className={`${styles.mobileLink} ${
              pathname === "/gallery" && styles.mobileLinkActive
            }`}
          >
            Gallery
          </Link>

          <Link
            to={"/contact"}
            className={`${styles.mobileLink} ${
              pathname === "/contact" && styles.mobileLinkActive
            }`}
          >
            Contact
          </Link>

          <Link
            to={"/contact/drivers"}
            className={`${styles.mobileLink} ${
              pathname === "/drivers" && styles.mobileLinkActive
            }`}
          >
            Drivers
          </Link>

          <Link
            to={"/contact/getaquote"}
            className={`${styles.mobileLink} ${
              pathname === "/getaquote" && styles.mobileLinkActive
            }`}
          >
            Get a Quote
          </Link>

          <Link
            to={"/contact/owners"}
            className={`${styles.mobileLink} ${
              pathname === "/owners" && styles.mobileLinkActive
            }`}
          >
            Owners
          </Link>
        </motion.div>
        <div className={styles.spacer} />
        <motion.div
          className={styles.socialWrapper}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: isDrawerOpen ? 1 : 0, x: isDrawerOpen ? 0 : -20 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <a
            href={"https://www.facebook.com/ctifleet/photos"}
            className={styles.socialLink}
            target={"_blank"}
          >
            <FaFacebookSquare />
            <div />
          </a>
          <a
            href={
              "https://www.linkedin.com/in/ctifleet-capitol-truckin-inc-51a062168/"
            }
            className={styles.socialLink}
            target={"_blank"}
          >
            <FaLinkedin />
            <div />
          </a>
        </motion.div>
      </motion.div>
      <motion.div
        className={isDrawerOpen ? styles.mobileOverlay : ""}
        initial={{ opacity: 0 }}
        animate={{ opacity: isDrawerOpen ? 0.15 : 0 }}
        transition={{ duration: 0.3 }}
      />
    </nav>
  )
}

export default Nav
