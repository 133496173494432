import React from "react"
import styles from "./footer.module.scss"
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaClock } from "react-icons/fa"

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.contactInfo}>
          <h2>Contact Us</h2>
          <div className={styles.infoItem}>
            <FaMapMarkerAlt />
            <span>
              1430 Bradley Ln., suite 150 A<br />
              Carrollton, Texas 75007
            </span>
          </div>
          <div className={styles.infoItem}>
            <FaPhoneAlt />
            <span>+1 877 494 7174</span>
          </div>
          <div className={styles.infoItem}>
            <FaEnvelope />
            <a href="mailto:hr@ctifleet.com">hr@ctifleet.com</a>
            <a href="mailto: quotes@ctifleet.com">quotes@ctifleet.com</a>
          </div>
          <div className={styles.infoItem}>
            <FaClock />
            <span>Mon - Fri: 6:00 AM - 5:00 PM</span>
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.about}>
            "Success is not final, failure is not fatal: It is the courage to
            continue that counts."
          </div>
          <div className={styles.socialMedia}>
            <a
              href="https://www.facebook.com/ctifleet/photos"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.linkedin.com/in/ctifleet-capitol-truckin-inc-51a062168/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>

      <div className={styles.copyright}>
        &copy; {new Date().getFullYear()} Capitol Trucking INC. All rights
        reserved.
      </div>
    </footer>
  )
}

export default Footer
