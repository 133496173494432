import { Link } from "react-router-dom"
import styles from "./notfound.module.scss"

const NotFound: React.FC = () => {
  return (
    <section className={styles.wrapper}>
      <h1>404</h1>
      <p>
        Looks like the page you were looking for doesn't exist. Take a journey
        of our website by clicking the button below or take a look for all
        available pages by following this <Link to={"/sitemap"}>link</Link>
      </p>
      <Link to={"/"}>Take me back</Link>
    </section>
  )
}

export default NotFound
